@import '../../styles/theme';

.flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 5%;
    height: 85vh;
    background: linear-gradient(0deg, #F8F8F8 70%, transparent 50%);

    @media screen and (max-width: 768px) {
        height: 86vh;
    }

    .footer {
        position: absolute;
        bottom: 30px;
    }
}
.title {
    margin: 0;
    width: 100%;
    line-height: 1.5;
    font-size: 32px;
    font-family: $freight;
    color: $secondary-color;
    font-weight: normal;
    width: 620px;
    margin-top: -30vh;

    @media screen and (max-width: 576px) {
        width: 85vw;
        font-size: 22px;
    }
    
    a {
        font-size: 32px;
        @media screen and (max-width: 576px) {
            font-size: 22px;
        }
    }

}

p {
    strong {
        a {
            font-family: $freight-med;
        }
    }
}

strong {
    font-family: $freight-med;
}