@import './styles/theme';

body {
  margin: 0!important;
  padding: 0;
  background-image: url('./assets/img/stripe.svg')!important;
  background-size: 350px 1px;
  height: 100%;
  font-family: $freight;
  font-size: 18px;
  line-height: 24px;
}

a {
  text-decoration: none;
  color: $secondary-color;
  transition: all .2s linear;
  cursor: pointer;
  &:hover {
    color: $primary-color;
  }
}