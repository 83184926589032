*, *::after, *::before { -webkit-box-sizing: border-box; box-sizing: border-box; }
* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  position: relative;
  min-height: 100%;
}

@font-face {
  font-family: 'Fabrikat Bold';
  src: url('./assets/fonts/fabrikat-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fabrikat Light';
  src: url('./assets/fonts/fabrikat-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fabrikat Medium';
  src: url('./assets/fonts/fabrikat-medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Freight Light';
  src: url('./assets/fonts/freighttextprolight-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Freight Medium';
  src: url('./assets/fonts/freighttextpromedium-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}