@import '../../styles/theme';

.post-page {
    width: 100%;
    min-height: 100vh;
    background-color: $secondary-color;
    
    .post-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 0;
        
        @media screen and (max-width: 768px) {
        padding: 30px 0;
        }
    }
}

.close {
    margin-bottom: 50px;
    @media screen and (max-width: 768px) {
        margin-bottom: 30px;
    }
}

.img-header {
    width: 1100px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @media screen and (max-width: 768px) {
        width: 85vw;
    }

    h1 {
        font-family: $fabrikat-bld;
        font-size: 9em;
        line-height: 1em;
        color: $primary-color;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        width: 100%;

        @media screen and (max-width: 768px) {
            font-size: 5em;
        }
    }
}

.img-wrapper {
    width: 735px;
    height: 895px;
    @media screen and (max-width: 768px) {
        width: 85vw;
    }
    @media screen and (max-width: 768px) {
        height: 415px;
    }
}

.post-content {
    max-width: 700px;
    width: 100%;
    color: aliceblue;
    padding: 30px 0;
    @media screen and (max-width: 768px) {
        max-width: 85vw;
    }
}

.post-content > div {
    margin-bottom: 30px;
}

.card-link {
    color : $text-color;
    font-family: $freight-med;
    text-align: center;
    display: block;
    margin-bottom: 20px;
}