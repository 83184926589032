// Brand Color

$background-color : #F8F8F8;
$primary-color: #FF7061;
$secondary-color: #B5BAB6;
$text-color: #F2F0ED;

// Brand Text

$fabrikat : 'Fabrikat Light', Arial, sans-serif !default;
$fabrikat-med : 'Fabrikat Medium', Arial, sans-serif !default;
$fabrikat-bld : 'Fabrikat Bold', Arial, sans-serif !default;

$freight : 'Freight Light', Arial, sans-serif !default;
$freight-med : 'Freight Medium', Arial, sans-serif !default;