@import '../../styles/theme';

.footer {
    width: 100%;
    height: 50px;
    background: $background-color;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        font-size: 12px;
    }
}