@import '../../styles/theme';

nav {
    text-align: center;
    padding: 30px 80px;
    
    @media screen and (max-width: 576px) {
        padding: 30px;
    }
}

ul {
display: flex;
justify-content: space-between;
align-items: center
}

nav > ul {
padding: 4px 16px;
}

li {
display: flex;
padding: 6px 8px;
}

a {
color: $secondary-color;
text-decoration: none;
font-size: 18px;
font-family: $fabrikat;
}