@import '../../styles/theme';

.hero {
    width: 100%;
    height: 165px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 200px;
    color: $secondary-color;

    @media screen and (max-width: 576px) {
        padding: 0 20px;
        height: 225px;
        margin-bottom: 120px;
    }

    .title {
        margin: 0;
        margin-bottom: 30px;
        width: 30%;
        line-height: 1.15;
        font-size: 32px;
        font-family: 'Freight Light','Arial', sans-serif;
        font-weight: normal;
        text-align: center;

        @media screen and (min-width: 768px) {
            width: 460px;
        }

        @media screen and (max-width: 768px) {
            width: 50%;
        }

        @media screen and (max-width: 576px) {
            width: 100%;
            font-size: 26px;
        }
    }

    span {
        font-size : 16px;
        @media screen and (max-width: 576px) {
            text-align: center;
        }
    }

}

.section {
    background-color: $background-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px; 
}

.row {
    max-width: 1100px;
    width: 100%;
    margin-top : -100px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

img.iconType {
    position: absolute;
    bottom: 8px;
    left: 46px;
    z-index: 2;
}

.card {
    position: relative;
    padding: 18px 18px 24px;
    width: 472px;
    text-align: left;
    text-decoration: none;
    display: flex;
    margin-bottom: 100px;

    @media screen and (max-width: 576px) {
        width: 85vw;
        margin-bottom: 30px;
    }

    h3 {
        margin: 0;
        color: $primary-color;
        font-family: $fabrikat-med;
        font-size: 28px;
        writing-mode: vertical-rl;
        text-orientation: sideways;
        margin-right: 20px;
        font-weight: normal;
    }
}

.cardContent {
    width: 387px;
    height: 500px;
    background-color: $secondary-color;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    transition: all .2s ease-in;

    @media screen and (max-width: 576px) {
        height: 350px;
    }

    &:hover {
        transform: scale(1.02)
    }
}

.cardImgWrapper {
    width: 270px;
    height: 360px;
    transition: all .3s ease-out;
    &:hover {
        transform: scale(0.9)!important
    }

    @media screen and (max-width: 576px) {
        width: 170px;
        height: 220px;
    }
}

.cardNumber {
    font-size: 68px;
    font-family: $fabrikat-bld;
    color: $primary-color;
    position: absolute;
    bottom: 20px;
    right: -10px;
    z-index: 2;
}
